@import "../shared_theme/fuse";
$asset-base-path: '/assets/cycle1tnecanope' !default;

@import "variables";
@import "variables-activities-colors";

@import "../../@modules/activities/scss/shared_theme/abstracts"; // partagé entre app et lms

@import "../shared_theme/default_theme";
@import "../shared_theme/main";

@import "modules/_corpus";




app-home-page .home-header {
    position: relative;
    height: auto;
    background: none;
    padding: 0 16px;
    margin: 0 0 24px 0;
}

app-lesson-page .files .content {
    mat-card {
        border: solid 1px $basic-page__icon--background-color;
    }
    .custom-nav #prev, .custom-nav #next {
        background: var(--bje-jaune);
        mat-icon{
            color: black;
        }
    }
}